/* eslint-disable */
import { Mutation, Module, VuexModule, Action } from "vuex-module-decorators";
import { useRoleBasedConditions, useUser } from '@/store/composable/User'
import moment from "moment"
import ApiService from "@/core/services/ApiService";
import { customerDetails } from '@/core/types/customers'
import { CustomerTop } from '@/core/types/customers'
import { common } from '@/store/stateless/common'

const { AgentDefaultRole,allowInitiateQuotes } = useRoleBasedConditions()
const { user_id } = useUser()

export interface CUSTOMER {
    customer: ICustomer
}

interface ICustomer {
    [key: string]: string | number | null
}

@Module
export default class Customer extends VuexModule implements CUSTOMER{
    componentLoad = false
    customerDetail = {} as customerDetails
    leads = []
    existCustomer = null
    isQLDisable = true
    viewTasks = []
    notes = []
    policies = []
    customer = {
        name: '',
        email: '',
        phone_country_code: '+974',
        phone_number: '',
        complete_phone_number: '',
        car_year: 0,
        manufacturer_id: 0,
        model_id: 0,
        trim_level_id: 0,
        is_new: 2,
        car_value: 0,
        first_registration_date: '',
        registration_emirate: 2,
        dob: '',
        nationality: null,
        first_driving_license_country: null,
        driving_experience: 4,
        uae_driving_experience: 4,
        policy_start_date: moment().format('YYYY-MM-DD'),
        claimed_insurance: 2,
        no_claim_certificate: 1,
        claims: null,
        gcc_specification: 1,
        personal_use: 1,
        current_policy_active: 1,
        fully_comprehensive: 1,
        third_party_liability: 2,
        existing_policy_expired: 2,
        driver_name: null,
        agent: AgentDefaultRole ? user_id : null,
        gender: null,
        is_car: 1,
        lead_source: 'skye',
        is_vintage: 0,
        vehicle_type: 1
    } as ICustomer
    customer_edit = {
        customer_id: 0,
        name: '',
        phone_country_code: '',
        phone_number: '',
        confirm_phone_country_code: '',
        confirm_phone_number: '',
        email: '',
        confirm_email: '',
        current_email: '',
        current_phone_number: '',
        other_contact_info: '',
        current_other_contact_info: ''
    }
    amend_invoice = {
        amend_type: 1
    }
    customerActivityLog = []
    customerStatusLog = []
    customerViewActivityLog = []
    documentLogs = []
    policyLogs = []
    emails = []
    manualQuotes = []
    sms = []
    leadActivityLog = []
    leadStatusLog = []
    responses = {}
    insurancePlan = []

    // Actions
    @Action
    async['GET_CUSTOMER_DETAILS'] (payload) {
        try {
            if(payload.clear){
                this.context.commit('SET_CUSTOMER_DETAILS', {})
                this.context.commit('SET_CUSTOMER_LEAD_DETAILS', [])
            }
            delete payload.clear

            const response = await ApiService.post('/misk-skye/customer/details', payload)
            this.context.commit('SET_CUSTOMER_DETAILS', response.data.data)
            this.context.commit('SET_CUSTOMER_LEAD_DETAILS', response.data.data)
            
            return response
        } catch (error) {
            return error
        }
    }

    @Action
    async ['CHECK_EXIST_PHONE_NUMBER'](payload) {
        this.context.commit('SET_QL_ENABLE', true) // put it in ql modal component
        const response = await ApiService.post('/skye/check-number-exist', payload)
        if(!response.data) this.context.commit('SET_QL_ENABLE', false)
        // this.context.commit('SET_EXIST_PHONE_NUMBER', response.data) // BND-1806
        return response.data
    }

    @Action
    async ['WITH_GLOBAL'](payload) {
        try {
            let url = getLink(payload.linkType)
            console.log({url})
            if(url.includes('PAGE_NUMBER')) url = url.replace('PAGE_NUMBER', payload.page)
            // delete payload.linkType
            // delete payload.page

            const response = await ApiService.post(url, payload)
            return response
        } catch (error) {
            return error
        }
    }

    @Action
    async ['GET_CUSTOMER_ACTIVITY_LOG'](payload) {
        try {
            const response = await ApiService.post('/misk-skye/customer/list-activity-logs', payload)
            this.context.commit('SET_CUSTOMER_ACTIVITY_LOG', response.data.data)
            return response
        } catch (error) {
            return error
        }
    }

    @Action
    async ['GET_CUSTOMER_STATUS_LOG'](payload) {
        try {
            const response = await ApiService.post('/misk-skye/customer/list-status-logs', payload)
            this.context.commit('SET_CUSTOMER_STATUS_LOG', response.data.data)
            return response
        } catch (error) {
            return error
        }
    }

    @Action
    async ['GET_ALL_TASK_BY_LEAD_AND_CUSTOMER_ID'](payload) {
        try {
            const response = await ApiService.post('/misk-skye/tasks/misk-lead-tasks', payload)
            this.context.commit('SET_ALL_TASK_BY_LEAD_AND_CUSTOMER_ID', response.data.data)
            return response
        } catch (error) {
            return error
        }
    }

    @Action
    async ['GET_ALL_NOTES_BY_LEAD_ID'](payload) {
        try {
            this.context.commit('SET_ALL_NOTES_BY_LEAD_ID', { notes: [] })
            const response = await ApiService.post('/misk-skye/notes', payload)
            this.context.commit('SET_ALL_NOTES_BY_LEAD_ID', response.data.data)
            return response
        } catch (error) {
            return error
        }
    }

    @Action
    async ['GET_LEAD_POLICIES'](payload) {
        try {
            const response = await ApiService.post('/skye/policies/list-car-customer-policies', payload)
            this.context.commit('SET_LEAD_POLICIES', response.data.data)
            return response
        } catch (error) {
            return error
        }
    }

    @Action
    async['GET_DOCUMENT_LOG'] (payload) {
        try {
            const response = await ApiService.post('/skye/documents/list-document-logs', payload)
            this.context.commit('SET_DOCUMENT_LOG', response.data.data)
            return response
        } catch (error) {
            return error
        }
    }

    @Action
    async['GET_POLICY_LOG'] (payload) {
        try {
            this.context.commit('SET_POLICY_LOG', {logs: []})
            const response = await ApiService.post('/skye/policies/list-policy-logs', payload)
            this.context.commit('SET_POLICY_LOG', response.data.data)
            return response
        } catch (error) {
            return error
        }
    }

    @Action
    async['GET_EMAILS'] (payload) {
        try {
            const response = await ApiService.post('/misk-skye/emails/list-emails', payload)
            this.context.commit('SET_EMAILS', response.data.data)
            return response
        } catch (error) {
            return error
        }
    }

    @Action
    async['GET_MANUAL_QUOTES'] (payload) {
        try {
            const response = await ApiService.post('/skye/quote/list-manual-quotes', payload)
            this.context.commit('SET_MANUAL_QUOTES', response.data.data)
            return response
        } catch (error) {
            return error
        }
    }

    @Action
    async['GET_SMS'] (payload) {
        try {
            const response = await ApiService.post('/skye/sms/list-sms', payload)
            this.context.commit('SET_SMS', response.data.data)
            return response
        } catch (error) {
            return error
        }
    }

    @Action
    async['GET_RESPONSES'] (payload) {
        try {
            const scene:any = {
                misk_lead_id: payload.lead_id,
                customer_id: payload.customer_id
            }
            const response = await ApiService.post('/skye/master/list-task-statuses', payload)
            const scenario = await ApiService.post('/skye/car-leads/check-scenario-eight', scene)
            this.context.commit('SET_RESPONSES', {responses: response.data.data})
            return scenario
        } catch (error) {
            return error
        }
    }

    @Action
    async['GET_LEAD_LOGS'] (payload) {
        try {
            const activity = await ApiService.post('/misk-skye/leads/list-activity-logs', payload)
            const status = await ApiService.post('/misk-skye/leads/list-status-logs', payload)
            this.context.commit('SET_LEAD_LOGS', {activity: activity.data.data.logs, status: status.data.data.logs})
            return { activity, status }
        } catch (error) {
            return error
        }
    }

    @Action
    async['GET_INSURANCE_PLAN'] (payload) {
        try {
            const response = await ApiService.post('/skye/policies/list-insurance-policies', payload)
            this.context.commit('SET_INSURANCE_PLAN', response.data.data)
            return response
        } catch (error) {
            return error
        }
    }

    @Action
    async['MAKE_INVOICE'] (payload) {
        try {
            const response = await ApiService.post_form('/skye/invoice/add-invoice', payload)
            return response
        } catch (error) {
            return error
        }
    }

    @Action
    async['APPROVE_CASH_PAYMENT'] (payload) {
        try {
            const response = await ApiService.post_form('/misk-skye/invoices/approve-cod-payment', payload)
            return response
        } catch (error) {
            return error
        }
    }
    
    // Mutations
    @Mutation
    ['SET_NEW_CUSTOMER'](payload) {
        this.customer = payload
    }

    @Mutation
    ['SET_CUSTOMER_DETAILS']({ customer }) {
        this.customerDetail = customer

        if(customer != undefined) {
            const edit = {
                customer_id: customer.id,
                name: customer.name,
                phone_country_code: '+974',
                phone_number: '',
                confirm_phone_country_code: '+974',
                confirm_phone_number: '',
                email: '',
                confirm_email: '',
                current_email: customer.email,
                current_phone_number: customer.phone_number,
                other_contact_info: '',
                current_other_contact_info: customer.other_contact_info ? customer.other_contact_info : '-'
            }
            this.customer_edit = edit
        }
    }

    @Mutation
    ['SET_CUSTOMER_DETAILS_EDIT']() {
        if(Object.keys(this.customerDetail).length > 0) {
            this.customer_edit = {
                customer_id: this.customerDetail.id,
                name: this.customerDetail.name,
                phone_country_code: '+974',
                phone_number: '',
                confirm_phone_country_code: '+974',
                confirm_phone_number: '',
                email: '',
                confirm_email: '',
                current_email: this.customerDetail.email,
                current_phone_number: this.customerDetail.phone_number,
                other_contact_info: '',
                current_other_contact_info: this.customerDetail.other_contact_info
            }
        }
    }

    @Mutation
    ['SET_CUSTOMER_LEAD_DETAILS']({ leads }) {
        this.leads = leads
    }

    @Mutation
    ['SET_LOADED_COMPONENT'](payload) {
        this.componentLoad = payload
    }

    @Mutation
    ['SET_EXIST_PHONE_NUMBER'](payload) {
        if(typeof payload === 'object') {
            this.existCustomer = payload
            this.isQLDisable = true
        } else {
            this.existCustomer = null
            this.isQLDisable = false
        }
    }

    @Mutation
    ['SET_QL_ENABLE'](payload) {
        this.isQLDisable = payload
    }

    @Mutation
    ['SET_RESET_CUSTOMER'](vehicle_type = 1) {
        this.customer = {
            name: '',
            email: '',
            phone_country_code: '+974',
            phone_number: '',
            car_year: 0,
            manufacturer_id: 0,
            model_id: 0,
            trim_level_id: 0,
            is_new: 2,
            car_value: 0,
            first_registration_date: '',
            registration_emirate: 2,
            dob: '',
            nationality: null,
            first_driving_license_country: null,
            driving_experience: 4,
            uae_driving_experience: 4,
            policy_start_date: moment().format('YYYY-MM-DD'),
            claimed_insurance: 2,
            no_claim_certificate: 1,
            claims: null,
            gcc_specification: 1,
            personal_use: 1,
            current_policy_active: 1,
            fully_comprehensive: 1,
            third_party_liability: 2,
            existing_policy_expired: 2,
            driver_name: null,
            agent: AgentDefaultRole ? user_id : null,
            gender: null,
            is_car: 1,
            lead_source: 'skye',
            is_vintage: 0,
            vehicle_type
        }
    }

    @Mutation
    ['SET_CUSTOMER_ACTIVITY_LOG']({ logs, logs_viewed }) {
        this.customerActivityLog = logs
        this.customerViewActivityLog = logs_viewed
    }
    
    @Mutation
    ['SET_CUSTOMER_STATUS_LOG']({ logs }) {
        this.customerStatusLog = logs
    }
    
    @Mutation
    ['SET_ALL_TASK_BY_LEAD_AND_CUSTOMER_ID']({ tasks }) {
        this.viewTasks = tasks
    }
    
    @Mutation
    ['SET_ALL_NOTES_BY_LEAD_ID']({ notes }) {
        this.notes = notes
    }

    @Mutation
    ['SET_LEAD_POLICIES']({policies}) {
        this.policies = policies
    }

    @Mutation
    ['SET_DOCUMENT_LOG']({logs}) {
        this.documentLogs = logs
    }

    @Mutation
    ['SET_POLICY_LOG']({logs}) {
        this.policyLogs = logs
    }

    @Mutation
    ['SET_EMAILS']({emails}) {
        this.emails = emails
    }

    @Mutation
    ['SET_MANUAL_QUOTES']({quotations}) {
        this.manualQuotes = quotations
    }

    @Mutation
    ['SET_SMS']({sms}) {
        this.sms = sms
    }

    @Mutation
    ['SET_RESPONSES']({ responses }) {
        this.responses = responses
    }

    @Mutation
    ['SET_LEAD_LOGS']({activity, status}) {
        this.leadActivityLog = activity
        this.leadStatusLog = status
    }

    @Mutation
    ['SET_INSURANCE_PLAN']({policies}) {
        this.insurancePlan = policies
    }

    @Mutation
    ['SET_AMEND_INVOICE'](data) {
        if(data) this.amend_invoice = data
    }

    // getters
    get getCustomerDetailsTop(): CustomerTop {
        if(this.customerDetail && Object.keys(this.customerDetail).length > 0) {
            console.log({'cust': this.customerDetail})
            const { name, email, id, phone_number, hot_lead, hot_renewal_lead, agent_details, customer_status_id, customer_status_text, customer_open_tasks_count, other_contact_info, complete_phone_number } = this.customerDetail
            return {
                customer_id: id,
                name,
                email: email ? email : 'Update Email',
                complete_number: complete_phone_number,
                customer_status_id,
                customer_status_text,
                agent_name: agent_details ? `SA: ${agent_details.name}` : 'Not Assigned',
                open_task_count: customer_open_tasks_count,
                hot_lead: hot_lead === 1 ? true : false,
                hot_renewal_lead: hot_renewal_lead === 1 ? true : false,
                other_contact_info: other_contact_info,
                name_email_phone: `${name} | ${ email ? email + ' |': '' } ${phone_number}`
            }
        }

        return {
            customer_id: 0,
            name: '',
            email: '',
            complete_number: '',
            customer_status_id: 0,
            customer_status_text: '',
            agent_name: '',
            open_task_count: 0,
            hot_lead: false,
            hot_renewal_lead: false,
            other_contact_info: '',
            name_email_phone: ''
        }
    }

    get getLeadDatas() {
        if(this.leads != undefined) {
            return this.leads.map((x: any) => {
                return {
                    tab_disabled: [2, 9, 10].includes(x.lead_status_id),
                    lead_status: x.lead_status_text,
                    is_end_state_lead: x.is_end_state_lead,
                    lead_status_id: x.lead_status_id,
                    highlight: x.misk_latest_task ? x.misk_latest_task.is_highlight_task : false,
                    lead_id: x.id,
                    is_show_closed_lead: x.is_show_closed_lead,
                    insurance_type: x.insurance_type,
                    is_show_make_invoice: x.is_show_make_invoice,
                    agent: x.agent,
                    name: `${x.header_section}`,
                    customer_id: x.customer_id,
                    start_date: (x.start_date) ? common.setDateOnly(x.start_date) : '-',
                    end_date: (x.end_date) ? common.setDateOnly(x.end_date) : '-',
                    coverage_area: x.coverage_name ?? '-',
                    policy_bought: !x.policy_bought ? ' No ' : ' Yes ',
                    task_due: !x.is_end_state_lead && x?.misk_latest_task?.status == 1 ? x.misk_latest_task?.due_date_format : 'No Task',
                    show_invoice: x.get_final_invoice ? true : false,
                    lead: leadData(x),
                    customer_detail: customerDetailData(x),
                    traveller_detail: travellerData(x),
                    payment_details: paymentData(x),
                    driver_details: driverDetailsData(x),
                    policy_details: policyDetailsData(x),
                    with_policy: x.latest_policy_purchase ? true : false,
                    vehicle_type: x.vehicle_type,
                    hot_lead: x.hot_lead === 1 ? true : false,
                    agent_name: x.agent_details ? x.agent_details.name : 'Not Assigned',
                    hot_renewal_lead: x.hot_renewal_lead === 1 ? true : false,
                }
            })
        }
        
        return []
    }

    get getCustomerLogs() {
        return {
            activity_log: activity_log(this.customerActivityLog),
            status_log: status_log(this.customerStatusLog),
            view_activity_log: view_activity_log(this.customerViewActivityLog)
        }
    }
    
    get getLeadActivityLogs() {
        return {
            activity_log: activity_log(this.leadActivityLog),
            status_log: status_log(this.leadStatusLog)
        }
    }

    get getLeadCounts() {
        return this.leads.length
    }

    get getTabDisabled() {
        if(this.leads.length < 2) {
            return this.leads.find((x: any) => [2, 9, 10].includes(x.lead_status_id)) ? true : false
        }
        return false
    }

    get getViewDetails() {
        if(this.leads !== undefined) {
            console.log(this.leads);
            return this.leads.map((x: any) => {
                if(x.insurance_type != 3) {
                    return {
                        agent: x.agent,
                        car_value: parseFloat(x.lead_driver_detail.car_value),
                        car_year: parseInt(x.car_year),
                        claimed_insurance: x.lead_driver_detail.claimed_insurance,
                        claims: x.lead_driver_detail.claims,
                        current_policy_active: x.lead_driver_detail.current_policy_active,
                        customer_id: x.customer_id,
                        dob: x.lead_driver_detail.dob,
                        driver_details_id: x.lead_driver_detail.id,
                        driver_name: x.lead_driver_detail.driver_name,
                        driving_experience: x.lead_driver_detail.driving_experience,
                        dummy_email: this.customerDetail.email,
                        email: this.customerDetail.email,
                        existing_policy_expired: x.lead_driver_detail.existing_policy_expired,
                        first_driving_license_country: x.lead_driver_detail.first_driving_license_country,
                        first_registration_date: x.first_registration_date,
                        fully_comprehensive: x.lead_driver_detail.fully_comprehensive,
                        gcc_specification: x.gcc_specification,
                        gender: x.lead_driver_detail.gender,
                        is_car: 1,
                        is_new: x.is_new,
                        is_vintage: x.is_vintage,
                        lead_id: x.id,
                        lead_source: x.lead_source,
                        manufacturer_id: x.manufacturer_id,
                        model_id: x.model_id,
                        name: this.customerDetail.name,
                        nationality: x.lead_driver_detail.nationality,
                        no_claim_certificate: parseInt(x.lead_driver_detail.no_claim_certificate),
                        personal_use: x.lead_driver_detail.personal_use,
                        phone_country_code: this.customerDetail.phone_country_code,
                        phone_number: this.customerDetail.phone_number_without_code,
                        policy_start_date: x.lead_driver_detail.policy_start_date,
                        policy_type: 0,
                        registration_emirate: x.registration_emirate,
                        third_party_liability: x.lead_driver_detail.third_party_liability,
                        trim_level_id: x.trim_level_id,
                        uae_driving_experience: x.lead_driver_detail.uae_driving_experience,
                        vehicle_type: x.vehicle_type
                    }
                } else {
                    let j = 0;
                    return x.details_of_traveler.map(x => {
                        j++;
                        return {
                            agent: x.agent,
                            customer_id: x.bai_misc_customer_id,
                            dummy_email: this.customerDetail.email,
                            email: this.customerDetail.email,
                            phone_country_code: this.customerDetail.phone_country_code,
                            phone_number: this.customerDetail.phone_number_without_code,
                            name: this.customerDetail.name,
                            traveller_index: j,
                            first_name: `${x.type_prefex} ${x.first_name}`,
                            last_name: x.last_name,
                            middle_name: x.middle_name,
                            lead_detail_id: x.id,
                            dob: x.dob,
                            gender: x.gender,
                            passport: x.passport,
                            country_of_residence: x.country_of_residence,
                            traveller_nationality: x.traveller_nationality,
                        }
                    });
                }
            })
        }

        return []
    }

    get getAllLeads() {
        if(this.leads !== undefined) {
            return this.leads.map((x: any) => {
                return {
                    lead_id: x.id,
                    customer_id: x.customer_id,
                    agent: x.agent,
                    lead_status_id: x.lead_status_id,
                    is_show_closed_lead: x.is_show_closed_lead,
                    name: `${x.header_section}`,
                    tab_disabled: [9, 10].includes(x.lead_status_id),
                    with_policy: x.latest_policy_purchase ? true : false,
                    vehicle_type: x.vehicle_type
                }
            })
        }
        return []
    }

    get getLeadDropDown() {
        if(this.leads !== undefined) {
            return this.leads.filter((x:any) => ![2, 10].includes(x.lead_status_id)).map((x:any) => {
                    return {
                        value: x.id,
                        label: `${x.car_name_with_driver} | ${x.id}`,
                        is_show_make_invoice: x.is_show_make_invoice,
                        lead_status_id: x.lead_status_id,
                        policy_purchase_status: x?.latest_policy_purchase?.status
                    }
            })
        }

        return []
    }

    get getAllTasks() {
        if(this.viewTasks !== undefined) {
            return this.viewTasks.map((x: any) => {
                return {
                    current_data: `${x.agent_details ? x.agent_details.name + ' | ' : ''}${x.disposition_text} | <span class="text-blue mx-2"> ${x.status_text} </span>`,
                    lost_lead_reason: x.lost_lead_reason_text,
                    show_lost_lead_reason: [8, 19].includes(x.disposition_id) ? true : false,
                    show_close_task: x.status === 1 ? true : false,
                    lead_status: x.lead_status_text,
                    task_note: x.task_notes,
                    class_text: x.class_text,
                    due_at: x.due_date_format,
                    by: `${x.created_by ? x.created_by.name + ' | ' : ''}${x.created_at}`,
                    closed_at: x.closed_at,
                    customer_id: x.customer_id,
                    agent: x.agent,
                    lead_id: x.lead_id,
                    lead_status_id: x.lead_status_id
                }
            })
        }
        return []
    }

    get getPolicies() {
        if(this.policies !== undefined) {
            return this.policies.map((x:any) => {
                    const show_view_policy = x.policy_document ? true : false
                    const show_view_all_documents = x.car_lead_documents && x.car_lead_documents.length > 0 ? true : false
                    const show_view_cancellationDocuments = x.status === 7 ? true : false

                return {
                    show_action: [1,2,3,4,7].includes(x.status) && (show_view_policy || show_view_all_documents || show_view_cancellationDocuments),
                    is_active: x.is_active === 1 ? true : false,
                    is_amend: x.is_amended === 1 ? true : false,
                    quote_ref_no: x.car_lead_quote.quote_ref_no,
                    status_text: x.status_text,
                    status: x.status,
                    email_mobile: `${x.customer.email}<br />${x.customer.complete_phone_number}`,
                    customer_name: x.customer.name,
                    price_vat: `${common.formatCurrency(x.policy_price_amount)} <br />${common.formatCurrency(x.car_lead_invoice.vat)}`,
                    price: common.formatCurrency(x.policy_price_amount),
                    price_vat_inline: `${common.formatCurrency(x.policy_price_amount)} (${common.formatCurrency(x.car_lead_invoice.vat)})`,
                    price_inline: common.formatCurrency(x.policy_price_amount),
                    amend_invoices: x?.car_lead_invoice?.amend_invoices ? amend_invoices(x.car_lead_invoice.amend_invoices) : [],
                    amend_invoices_without_vat: x?.car_lead_invoice?.amend_invoices ? amend_invoices_without_vat(x.car_lead_invoice.amend_invoices) : [],
                    discrepancy_amount: common.formatCurrency(x?.car_lead_invoice?.discrepancy_amount ? x.car_lead_invoice.discrepancy_amount : 0),
                    transaction_type_text: x.transaction_type_text,
                    policy_type: x?.car_insurance_policy?.policy_type == 5 ? 'TPL' : 'COMP',
                    policy_name: x?.car_insurance_policy?.policy_name ? x.car_insurance_policy.policy_name : '<span class="text-danger">-</span>',
                    created_on: `${x.created_at ? x.created_at : '-'}<br />${x.issue_date ? x.issue_date : '-'}`,
                    policy_start_date: x.policy_start_date_format ? x.policy_start_date_format : '-',
                    policy_end_date: x.policy_end_date_format ? x.policy_end_date_format : '-',
                    upsa: `${x.policy_underwriter ? x.policy_underwriter.name : '<span class="text-danger"> Not assigned</span>'}<br />${ x.policy_sales_agent ? x.policy_sales_agent.name : '-' }`,
                    ibco: `${x?.car_lead_invoice?.created_by ? x.car_lead_invoice.created_by.name : '-'}<br />${ x.car_lead_invoice ? x.car_lead_invoice.created_at : '-' }`,
                    car_value: common.formatCurrency(x?.car_lead_quote?.car_lead_driver_details ? x?.car_lead_quote?.car_lead_driver_details?.car_value : 0),
                    insurance_payment_text: x.insurance_payment_text ? x.insurance_payment_text : '-',
                    show_view_policy,
                    show_view_all_documents,
                    show_view_cancellationDocuments,
                    policy_document: x.policy_document ? x.policy_document.file_path : '',
                    policy_documents: allDocuments(x.car_lead_documents, x.car_lead_quote.quote_ref_no),
                    cancellation_documents: allDocuments(x.car_lead_cancellation_documents, x.car_lead_quote.quote_ref_no, 'cancellation', false)
                }
            })
        }

        return []
    }

    get getPolicyLogItems() {
        if(this.policies !== undefined) {
            return this.policies.map((x:any) => {
                return {
                    label: `${x?.car_insurance_policy?.policy_name ? x.car_insurance_policy.policy_name : ''} | ${x.car_lead_quote.quote_ref_no} | ${moment(x.created_at, 'DD/MM/YYYY HH:mm').format('YYYY')}`,
                    value: x.id
                }
            })
        }
        return []
    }

    get getDocumentLogLists() {
        if(this.documentLogs !== undefined) {
            return this.documentLogs.map((x:any) => {
                return {
                    action: x.action,
                    by: x.action_by.name,
                    at: x.created_at
                }
            })
        }

        return []
    }

    get getPolicyLogLists() {
        if(this.policyLogs !== undefined) {
            return this.policyLogs.map((x:any) => {
                let policy_status = ''
                let action = ''
                let current_data = ''
                let previous_data = ''
                if(['refund-approved','refund-decline','refund-completed','policy-updated'].includes(x.activity_slug)) {
                    policy_status = x?.activity_description?.new?.policy_status
                    current_data = setCurrentData(x?.activity_description?.new)
                    previous_data = setCurrentData(x?.activity_description?.old)
                }
                if(['policy-discrepancy'].includes(x.activity_slug)) {
                    action = x?.activity_description?.new?.action
                    current_data = setCurrentData(x?.activity_description?.new, true)
                    previous_data = setCurrentData(x?.activity_description?.old, true)
                }
                if(['policy-transferred', 'sales-transferred'].includes(x.activity_slug)) {
                    current_data = x?.activity_description?.new?.name
                    previous_data = x?.activity_description?.old?.name
                }
                if(['policy-discrepancy'].includes(x.activity_slug)) {
                    current_data = x?.activity_description?.new?.created_at
                }
                if(['updated-completed-policy-info','updated-cancelled-policy-info'].includes(x.activity_slug)) {
                    policy_status = x?.activity_description?.policy_status
                    current_data = x?.activity_description?.new
                    previous_data = x?.activity_description?.old
                }

                return {
                    title: x.activity_desc,
                    policy_status,
                    action,
                    current_data,
                    previous_data,
                    by: x.action_by ? `${x?.action_by?.name} at ${moment(x.created_at, 'DD/MM/YYYY HH:mm').zone('+0400').format('DD/MM/YYYY HH:mm')}` : ''
                }
            })
        }

        return []
    }

    get getEmails() {
        if(this.emails !== undefined) {
            return this.emails.map((x:any) => {
                return {
                    subject: x.subject,
                    sent_by: x.sent_by_skye_user ? x.sent_by_skye_user.name : 'System',
                    created_at: x.created_at,
                    delivered: x.delivered == 1 ? true : false,
                    clicked: x?.clicked == 1 ? true : false,
                    email_body: x.body
                }
            })
        }

        return []
    }

    get getViewQuotes() {
        if(this.manualQuotes !== undefined) {
            return this.manualQuotes.map((x:any) => {
                return {
                    id: x.id,
                    car_details: `${x.car_year} | ${x.make} | ${x.model} | ${x.model_details}`,
                    policy_type: x.is_mark_flow == 1 ? x.mark_flow_policy_type_text : x.policy_type_text,
                    generate_on: x.created_at,
                    generate_by: x.created_by?.name,
                    companies: x.companies,
                    payment_link: x.payment_requested == 2 ? x.payment_link : x.payment_requested_text,
                    file_path: x.file_path,
                    encrypted_file_path: x.encrypted_file_path,
                    showDownload: true,
                    showSendQuote: true,
                    showWhatsapp: true,
                    is_mark_flow: x.is_mark_flow == 1 ? true : false,
                    whatsapp_number: this.customerDetail.whatsapp_number
                }
            })
        }

        return []
    }

    get getSMS() {
        if(this.sms !== undefined) {
            return this.sms.map((x:any) => {
                return {
                    sms_content: x.sms,
                    created_at: x.created_at,
                    status: x.status == 1 ? 'Delivered' : 'Not delivered'
                }
            })
        }
        return []
    }

    get getResponses() {
        return (lead_status_id, with_policy) => {
            return this.responses[getResponseObject(lead_status_id, with_policy)]
        }
    }

    get getVehicleListByType() {
        if(this.leads != undefined) {
            return (vehicle_type = 1) => {
                const vehicle = this.leads.filter((x:any) => x.vehicle_type === vehicle_type)
                return vehicle.map((x: any) => {
                    return {
                        label: `${x.car_year} | ${x.make} | ${x.model} | ${x.model_details}`,
                        value: x.id
                    }
                })
            }
        }

        return []
    }

    get ProduceDocuments() {
        return (documents, quote_ref_no, module = 'policy', is_completed = true, types = [], params = null) => {
            return allDocuments(documents, quote_ref_no, module, is_completed, types, params) 
        }
    }

    get ProduceAmendInvoice() {
        return (data, amend_type) => {
            if(amend_type === 3) return setANT(data, amend_type)
            else return setNotNT(data, amend_type)
        }
    }

    get getGraphData() {
        return (data) => {
            if(data != undefined) {
                return {
                    "series": data.counts.filter(x => x).map(x=> parseInt(x)),
                    "options": {
                        "chart": {
                            "width": 400,
                            "height": 250,
                            "type": "pie"
                        },
                        "colors": data.colors,
                        "labels": data.names,
                        "responsive": [{
                            "breakpoint": 400,
                            "options": {
                                "chart": {
                                    "width": 400,
                                    "height": 250,
                                },
                                "legends": {
                                    "position": "bottom"
                                }
                            } 
                        }]
                    }
                };
            }
        }
    }

}

function getLink(value: string) {
    switch(value) {
        case 'update-name': return '/skye/customer/update-customer-name'
        case 'update-email': return '/skye/customer/update-customer-email'
        case 'update-phone': return '/skye/customer/update-customer-phone'
        case 'update-customer-info': return '/misk-skye/customer/update-customer-info' // MiskRoute
        case 'transfer-leads': return '/misk-skye/leads/transfer-lead' // MiskRoute
        case 'transfer-sale': return '/misk-skye/policies/transfer-sale' // MiskRoute
        case 'customer-add-detail': return '/skye/car-detailed-lead'
        case 'customer-add-new-bike': 
        case 'customer-add-bike-detail': return '/skye/bike-detailed-lead'
        case 'add-driver-details': 
        case 'add-lead-driver-details': 
        case 'new-car-quote': return '/skye/car-leads/add-driver-details'
        case 'edit-quote': return '/skye/car-leads/update-driver-details'
        case 'edit-bike-quote': return '/skye/bike-leads/update-driver-details'
        case 'generate-driver-details-manaual-quote':
        case 'generate-manaual-quote': return '/skye/quote/add-manual-quote'
        case 'add-notes': return '/misk-skye/notes/add-note' // MiskRoute
        case 'document-logs': return '/skye/documents/add-document-log'
        case 'compose-email': return '/misk-skye/emails/send-email'  // MiskRoute
        case 'lead-policy-status': return '/skye/policies/get-policy-lead'
        case 'completed-documents': return '/skye/policies/list-lead-policy-documents'
        case 'active-underwriters': return '/skye/get-active-underwriters'
        case 'temp-documents': return '/skye/documents/list-temp-document'
        case 'amend-documents': return '/skye/documents/list/amends'
        case 'document-download': return '/skye/master/generate-zip'
        case 'document-types': return '/skye/master/document-types'
        case 'task-lead-reasons': return '/skye/master/task-lead-reasons'
        case 'cancellation-reasons': return '/skye/master/cancellation-reasons'
        case 'refund-reasons': return '/skye/master/refund-reasons'
        case 'list-user-types': return '/misk-skye/master/list-user-types' // MiskRoute
        case 'document-type-update': return '/skye/documents/update-temp-document'
        case 'delete-document': return '/skye/documents/delete-temp-document'
        case 'delete-policy-document': return 'skye/policies/delete-policy-document'
        case 'send-quote-request': return '/skye/car-leads/quote-request'
        case 'previous-year-documents': return '/skye/policies/list-previous-year-lead-policy-documents'
        case 'insurance-companies': return '/skye/quote/list-insurance-policies'
        case 'send-manual-quote': return '/skye/quote/send-manual-quote'
        case 'add-lead-task': return '/misk-skye/tasks/add-lead-task' // MiskRoute
        case 'update-close-lead': return '/misk-skye/leads/update-closed-lead' // MiskRoute
        case 'ql-merge-lead': return '/skye/merge-quick-car-lead'
        case 'new-merge-lead': return '/skye/car-leads/merge-new-car-lead'
        case 'send-quote': return '/skye/quote/send-quote'
        case 'get-cancelled-policies': return '/skye/policies/list-cancelled-policies'
        case 'send-email-it': return '/skye/emails/send-email-invoice-issue'
        case 'get-policy-amount-accepted': return '/skye/policies/get-policy-amount-accepted'
        case 'email-activity': return '/skye/policies/list-email-activiy'
        case 'policy-restrict-dates': return '/skye/settings/policy-start-date-days-restrict'
        case 'send-mail-customer': return '/skye/policies/send-email-customer'
        case 'send-mail-broker': return '/skye/policies/send-email-insurance-broker'
        case 'ocr-check': return '/skye/policies/ocr-reader'
        case 'save-as-draft': return '/skye/policies/save-draft'
        case 'upload-policy': return '/skye/policies/add-car-customer-policy'
        case 'initiate-cancellation': return '/skye/policies/cancel-policy'
        case 'initiate-refund': return '/skye/policies/refund'
        case 'customer-list': return '/misk-skye/customer/list?page=PAGE_NUMBER' // MiskRoute
        case 'policy-completed':
        case 'policy-cancellation-request':
        case 'policy-refund-closed':
        case 'policy-cancelled':
        case 'policy-cancellation-approval':
        case 'policy-assigned': return '/misk-skye/policies/list-policies-v3?page=PAGE_NUMBER' // MiskRoute
        case 'customer-list-count': return '/skye/customer-list-count'
        case 'agent-list': return '/misk-skye/master/agent-list' // MiskRoute
        case 'agent-list-dom': return '/misk-skye/master/agent-list-for-dom-new' // MiskRoute
        case 'underwriter-list': return '/skye/underwriter-list'
        case 'customer-lead-count': return '/misk-skye/customer/customer-lead-count' //MiskRoute
        case 'task-due-date-count': return '/misk-skye/tasks/lt-open-task-counts' // MiskRoute
        case 'filtered-task-due-date-count': return '/misk-skye/leads/filtered-task-due-date-count' //MiskRoute
        case 'list-policies-count': return '/misk-skye/policies/list-policies-count' // MiskRoute
        case 'has-child-policy': return '/skye/policies/get-child-invoices'
        case 'amend-invoice': return '/skye/invoice/amend-invoice'
        case 'insurance-types-list': return '/misk-skye/master/insurance-types-list' // MiskRoute
        case 'approve-cod-payment': return '/misk-skye/invoices/approve-cod-payment' // MiskRoute
        case 'sales-agent-manager-dashboard': return '/misk-skye/dashboard/sales-agent-manager-dashboard' // MiskRoute
        case 'overview-dashboard': return '/misk-skye/dashboard/overview' // MiskRoute
        case 'sales-person-info-dashboard': return '/misk-skye/dashboard/sales-person-info' // MiskRoute
        case 'statistics-dashboard': return '/misk-skye/dashboard/statistics' // MiskRoute
        case 'other-info-misk-leads': return '/misk-skye/dashboard/other-info-misk-leads?page=PAGE_NUMBER' // MiskRoute
        case 'other-info-transactions': return '/misk-skye/dashboard/other-info-transactions?page=PAGE_NUMBER' // MiskRoute
        case 'other-info-providers': return '/misk-skye/dashboard/other-info-providers' // MiskRoute
        case 'marketing-dashboard': return '/misk-skye/dashboard/marketing-dashboard' // MiskRoute
        case 'accounts-dashboard': return '/misk-skye/dashboard/accounts-dashboard' // MiskRoute
        case 'expired-leads': return '/misk-skye/reports/expired-leads?page=PAGE_NUMBER' // MiskRoute
        case 'export-expired-leads': return '/misk-skye/reports/exports/expired-leads?page=PAGE_NUMBER' // MiskRoute
        case 'lead-source-filter': return '/misk-skye/reports/master/lead-source-filter' // MiskRoute
        case 'lead-by-source': return '/misk-skye/reports/lead-by-source?page=PAGE_NUMBER' // MiskRoute
        case 'export-lead-by-source': return '/misk-skye/reports/exports/lead-by-source?page=PAGE_NUMBER' // MiskRoute
        case 'account-master-filter': return '/misk-skye/reports/master/account-master-filter' // MiskRoute
        case 'account-master': return '/misk-skye/reports/account-master?page=PAGE_NUMBER' // MiskRoute
        case 'export-account-master': return '/misk-skye/reports/exports/account-master?page=PAGE_NUMBER' // MiskRoute
        case 'admin-report': return '/misk-skye/reports/admin-report' // MiskRoute
        case 'export-admin-report': return '/misk-skye/reports/exports/admin-report' // MiskRoute
        case 'customers-without-lead': return '/misk-skye/reports/customers-without-lead' // MiskRoute
        case 'export-customers-without-lead': return '/misk-skye/reports/exports/customers-without-lead' // MiskRoute
        case 'dashboard-details': return '/misk-skye/reports/dashboard-details?page=PAGE_NUMBER' // MiskRoute
        case 'export-dashboard-details': return '/misk-skye/reports/exports/dashboard-details?page=PAGE_NUMBER' // MiskRoute
        case 'count-unread-notification': return '/misk-skye/notifications/count-unread-notification' // MiskRoute
        case 'list-recent-notifications': return '/misk-skye/notifications/list-recent-notifications?page=PAGE_NUMBER' // MiskRoute
        case 'update-notification': return '/misk-skye/notifications/update-notification' // MiskRoute
        case 'update-click-notification': return '/misk-skye/notifications/update-click-notification' // MiskRoute
        case 'generate-production-report' : return '/misk-skye/reports/generate-production-report'
        case 'production-reports' : return '/misk-skye/reports/get-production-report'
        case 'invoice-list' : return '/misk-skye/invoices/list-invoice-v3?page=PAGE_NUMBER'
        case 'invoice-list-count' : return '/misk-skye/invoices/list-invoice'
        case 'reset-password': return '/skye/user-reset-password'
        case 'request-reset-password': return '/skye/user-request-reset-password'
        case 'marketing-report': return '/misk-skye/reports/marketing-campaign-report'
        case 'marketing-report-agent': return '/misk-skye/reports/marketing-campaign-report'
        case 'marketing-spend': return '/misk-skye/marketing/campaigns/spend/filters'
        case 'marketing-spend-tracking': return '/misk-skye/marketing/campaigns/spend/tracking'
        case 'marketing-spend-add-update': return '/misk-skye/marketing/campaigns/spend/addUpdate'
        case 'get-switch-app-dropdown': return '/skye/get-switch-app-dropdown'
        default: return ''
    }
}

function activity_log(logs) {
    if(logs != undefined) {
        return logs.map(x => {
            let current_data = x.activity_description.new
            let showCurrentData = true
            let showPrevData = true
            if(current_data && ['transfer-customer','agent-assigned','customer-handover', 'agent-assigned', 'transfer-lead', 'lead-handover', 'task-handover', 'updated-policy-sales-agent'].includes(x.activity_slug)) {
                current_data = current_data.name
            } 
            let previous_data = x.activity_description.old
            if(previous_data && ['transfer-customer','agent-assigned','customer-handover', 'agent-assigned', 'transfer-lead', 'lead-handover', 'task-handover', 'updated-policy-sales-agent'].includes(x.activity_slug)) {
                previous_data = previous_data.name
                if(['updated-policy-sales-agent'].includes(x.activity_slug)) showPrevData = false
            }

            if(['added-notes', 'quick-lead-merged', 'false-lead', 'invoice-made', 'invoice-decline', 'invoice-decline-lost-lead','payment-approved','payment-made'].includes(x.activity_slug)) {
                showPrevData = false
            }

            if(['is-hot-lead','not-hot-lead','is-hot-renewal-lead'].includes(x.activity_slug)) {
                showCurrentData = false
                showPrevData = false
            }
            


            return {
                title: x.activity_desc,
                current_data: current_data ? current_data : '',
                previous_data: previous_data ? previous_data : '',
                by: `${x.action_by ? x.action_by.name : ''} ${common.setDate(x.created_at)}`,
                showPrevData,
                showCurrentData,
                showBy: true
            }
        })
    }
}

function status_log(logs) {
    if(logs != undefined) {
        return logs.map(x => {
            return {
                current_status: x.activity_description ? x.activity_description.new : x.new_status_text ? x.new_status_text : '',
                previous_status: x.activity_description ? x.activity_description.old : x.old_status_text !== 0 ? x.old_status_text : '',
                by: `${x.action_by ? x.action_by.name : ''} ${common.setDate(x.created_at)}`
            }
        })
    }
}

function view_activity_log(logs) {
    if(logs != undefined) {
        return logs.map(x => {
            return {
                description: x.activity_desc,
                by: `${x.action_by ? x.action_by.name : ''} ${common.setDate(x.created_at)}`
            }
        })
    }
}

export function amend_invoices(invoices) {
    if(invoices && invoices.length > 0){
        return invoices.map(x => {
            return {
                quote_ref_no: x.merchant_reference,
                price_vat_inline: `${x.amount} (${x.vat})`
            }
        })
    }

    return []
}

export function amend_invoices_without_vat(invoices) {
    if(invoices && invoices.length > 0){
        return invoices.map(x => {
            return {
                quote_ref_no: x.merchant_reference,
                price_vat_inline: x.amount
            }
        })
    }

    return []
}

export function allDocuments(documents, quote_ref_no, module = 'policy', is_completed = true, types = [], shows:any = null) {
    if(documents && documents.length > 0) {
        let showDelete = false
        let showDeleteOnUpload = false
        let showLog = true
        let showDownload = true
        if(shows) {
            showDelete = shows.showDelete
            showDeleteOnUpload = shows.showDeleteOnUpload
            showLog = shows.showLog
            showDownload = shows.showDownload
        }
        let quoteRefNo = quote_ref_no
        
        return documents.map(x => {
            let uploaded_by = 'Not found'
            let title = x.title
            const encrypted_file_path = x.encrypted_file_path
            uploaded_by = x.created_by_customer ? x.created_by_customer.email : (x.created_by_skye_user ? x.created_by_skye_user.email : '-')
            let uploaded = `${x.created_at}<br />${uploaded_by}`
            if(module == 'amendment') {
                quoteRefNo = x.quote_ref_no
                title = x.type_name
                // encrypted_file_path = x.file_public_path
                uploaded = `${x.updated_at_format}<br />${uploaded_by}`
            }
            return {
                id: x.id,
                extension: x.file_extension.toLowerCase(),
                file_path: x.file_path,
                file_public_path: x.file_public_path,
                encrypted_file_path,
                title,
                original_filename: x.original_filename,
                quote_ref_no: quoteRefNo,
                uploaded_by: uploaded,
                is_completed,
                showDelete,
                showDeleteOnUpload,
                showLog,
                showDownload,
                module,
                document_type: x.document_type?.document_type,
                type: x.type ? x.type : 0,
                types,
                is_temp: !is_completed
            }
        })
    }

    return []
}

function setCurrentData(item, isDiscrepancy = false) {
    if(!item) return ''

    if(isDiscrepancy) return `${item.discrepancy_amount} | ${item.discrepancy_underwriter} | ${item.discrepancy_approver}`
    else return`${item.underwriter} | ${item.policy_number} | ${item.chasis_no} | ${item.policy_start_date} | ${item.credit_notes} | ${item.debit_notes} | ${item.policy_sales_agent}`

}

function checkAction(currentValue) {
    return !currentValue
}

function leadData(lead) {
    let hide_action = false
    const all_lead_action_values = Object.values(lead.all_lead_actions)
    hide_action = all_lead_action_values.every(checkAction)

    return {
        show: true,
        vehicle_details: `${lead.header_section}`,
        sub_title: lead.sub_title,
        lead_status: lead.lead_status_text,
        lead_status_id: lead.lead_status_id,
        lead_id: lead.id,
        migrated_lead_id: lead.migrated_lead_id,
        task_status: lead?.misk_latest_task?.disposition_text,
        due_at: lead?.misk_latest_task?.due_date,
        request_time_line: lead.created_at,
        duplicate_source: lead.duplicate_source,
        vehile_type: lead.vehicle_type,
        insurance_type_text: lead.insurance_type_text,
        insurance_type_id: lead.insurance_type,
        is_end_state_lead: lead.is_end_state_lead,
        lead_source: lead.lead_source,
        time_lines: timelines(lead),
        driver_id: lead?.lead_driver_detail?.id,
        customer_id: lead.customer_id,
        uuid: lead.uuid,
        compare_page: lead.comparePage,
        initiate_quote: lead.initiateQuote,
        insured_for: lead.insured_for,
        application_status: lead.application_status,
        policy_document: lead.policy_document,
        items: leadDataArray(lead),
        final_invoice: lead.get_final_invoice,
        bai_ref_no: lead.get_final_invoice?.quote_ref_no ?? '-',
        total_price_vat: lead.get_final_invoice?.total_price_vat ? common.formatCurrency(lead.get_final_invoice?.total_price_vat)  : '-',
        payment_mode: lead.get_final_invoice?.payment_type_text ?? '-',
        destination_country: lead.destination_country,
        start_date: (lead.start_date) ? common.setDateOnly(lead.start_date) : '-',
        end_date: (lead.end_date) ? common.setDateOnly(lead.end_date) : '-',
        hide_action,
        travellers: travellerData(lead),
        ...showButtons(lead)
    }
}

function travellerData(lead) {
    let j = 0;
    return lead.details_of_traveler.map(x => {
        j++;
        let first_name =  `${x.type_prefex ? x.type_prefex : ''} ${x.first_name ? x.first_name : ''}`
        if(!x.type_prefex && !x.first_name) first_name = '-'
        return {
            show: true,
            traveller_index: j,
            first_name,
            last_name: x.last_name ? x.last_name : '-',
            middle_name: x.middle_name ? x.middle_name : '-',
            lead_detail_id: x.id,
            dob: x.date_of_birth ? x.date_of_birth : '-',
            gender: x.gender ? x.gender : '-',
            passport: x.passport ? x.passport : '-',
            country_of_residence: x.country_of_residence ? x.country_of_residence : '-',
            traveller_nationality: x.traveller_nationality ? x.traveller_nationality : '-',
            items: travellerDataArray(x, j),
        }
    });
}

function paymentData(lead) {
    return {
        show: lead.get_final_invoice ? true : false,
        items: paymentDataArray(lead),
        bai_ref_no: lead.get_final_invoice?.quote_ref_no ?? '-',
        total_price_vat: lead.get_final_invoice?.total_price_vat ? common.formatCurrency(lead.get_final_invoice?.total_price_vat)  : '-',
        approved_date: lead.get_final_invoice?.payment_date ?  common.setDate(lead.get_final_invoice?.payment_date): '-',
        payment_mode: lead.get_final_invoice?.payment_type_text ?? '-',
        right_text: lead.promo_code ? lead.promo_code.promotion_code : '',
    }
}

function customerDetailData(lead) {
    let gcc_spec = ''
    let vehicle_use = ''
    let existing_policy = ''
    if(lead.vehicle_type == 2) {
        gcc_spec = 'NA'
        vehicle_use = 'NA'
    }
    else {
        if(!lead.gcc_specification) gcc_spec = '-'
        else gcc_spec = lead.gcc_specification == 1 ? 'Yes' : 'No'

        if(!lead?.lead_driver_detail?.personal_use) vehicle_use = '-'
        else vehicle_use = lead.lead_driver_detail.personal_use == 1 ? 'Personal' : 'Commercial'
    }

    if(!lead.lead_driver_detail?.third_party_liability) existing_policy = '-'
    existing_policy = lead.lead_driver_detail?.third_party_liability == 1 ? 'Yes' : 'No'
    return {
        show: lead.lead_driver_detail && lead.model != 'QL' ? true : false,
        vehile_type: lead.vehicle_type,
        estimated_value: common.formatCurrency(lead?.lead_driver_detail?.car_value),
        is_new: lead.is_new == 1 ? 'Yes' : 'No',
        registration_emirate: lead.registration_emirate_text,
        first_registration_date: lead.first_registration_date_format,
        policy_active: lead?.lead_driver_detail?.current_policy_active == 1 ? 'Yes' : 'No',
        expected_policy: lead.lead_driver_detail && lead.lead_driver_detail.policy_start_date_format ? lead.lead_driver_detail.policy_start_date_format : '-',
        gcc_spec,
        vehicle_use,
        existing_policy
    }
}

function driverDetailsData(lead) {
    let gender = ''
    if(lead.lead_driver_detail) {
        if(lead.lead_driver_detail?.gender == 1) gender = 'fa fa-male text-info'
        else if(lead.lead_driver_detail?.gender == 2) gender = 'fa fa-female text-info'
        else if(lead.lead_driver_detail?.gender == 3) gender = 'icon-svg icon-rather-not-say'
        else gender = ''
    }
    let claims_made = ''
    let no_claims = ''
    let no_claim_certificate = ''
    if(lead.vehicle_type == 2) {
        claims_made = 'NA'
        no_claims = 'NA'
        no_claim_certificate = 'NA'
    }
    else {
        if(!lead.lead_driver_detail?.claimed_insurance) claims_made = '-'
        else claims_made = lead.lead_driver_detail && lead.lead_driver_detail.claimed_insurance == 1 ? 'Yes' : 'No'

        no_claims = lead.lead_driver_detail?.claims && lead.lead_driver_detail?.claimed_insurance == 1 ? lead.lead_driver_detail.claims : '-'

        no_claim_certificate = lead.lead_driver_detail ? lead.lead_driver_detail.no_claim_certificate_text : '-'
    }
    return {
        show: lead.lead_driver_detail && lead.lead_driver_detail.dob ? true : false,
        dob: lead.lead_driver_detail ? lead.lead_driver_detail.dob_format : '-',
        nationality: lead.lead_driver_detail ? lead.lead_driver_detail.nationality_country : '-',
        first_license_country: lead.lead_driver_detail ? lead.lead_driver_detail.first_license_country : '-',
        driving_experience: lead.lead_driver_detail ? lead.lead_driver_detail.driving_experience_text : '-',
        uae_driving_experience: lead.lead_driver_detail ? lead.lead_driver_detail.uae_driving_experience_text : '-',
        driver_name: lead.lead_driver_detail ? lead.lead_driver_detail.driver_name : '-',
        gender,
        claims_made,
        no_claims,
        no_claim_certificate
    }
}

function policyDetailsData(lead) {
    if(lead.latest_policy_purchase) {
        let policy_type = '-'
        if(!lead.latest_policy_purchase.car_insurance_policy) policy_type = '-'
        else {
            if(lead.latest_policy_purchase?.car_insurance_policy.policy_type == 5) policy_type = 'THIRD PARTY'
            else policy_type = 'COMPREHENSIVE'
        }
        return {
            show: lead.latest_policy_purchase ? true : false,
            status_value: lead.latest_policy_purchase.status_text,
            active_status_value: lead.latest_policy_purchase.policy_active_status == true ? 'Active' : 'In-Active',
            policy_expiry_date: lead.latest_policy_purchase.policy_end_date ? lead.latest_policy_purchase.policy_end_date_format : '-',
            quote_no: lead.latest_policy_purchase.car_lead_quote ? lead.latest_policy_purchase.car_lead_quote.quote_ref_no : '-',
            policy_name: lead.latest_policy_purchase?.car_insurance_policy?.policy_name,
            policy_type,
            premium: common.formatCurrency(lead.latest_policy_purchase.policy_price_amount),
            registration_emirate: lead.registration_emirate_text,
            car_value: common.formatCurrency(lead.lead_driver_detail?.car_value),
            chassis_no: lead.latest_policy_purchase.chassis_number ? lead.latest_policy_purchase.chassis_number : '-',
            transaction_type: lead.latest_policy_purchase.transaction_type_text ? lead.latest_policy_purchase.transaction_type_text : '-',
            policy_sa: lead.latest_policy_purchase.policy_sales_agent ? lead.latest_policy_purchase.policy_sales_agent.name : '-',
            underwriter: lead.latest_policy_purchase.policy_underwriter ? lead.latest_policy_purchase.policy_underwriter.name : '-',
            policy_issued_on: lead.latest_policy_purchase.issue_date ? lead.latest_policy_purchase.issue_date : '-',
            vehile_type: lead.vehicle_type,
            documents: allDocuments(lead.latest_policy_purchase?.car_lead_documents, lead.latest_policy_purchase.car_lead_quote.quote_ref_no)
        }
    }

    return {
        show: false
    }
}

function timelines(lead) {
    return {
        current_visited_date: lead.current_visited_date_format,
        last_visited_date: lead.previous_visited_date_format,
        created_at: lead.created_at
    }
}

function getResponseObject(lead_status_id, with_policy) {
    if(lead_status_id == 4) {
        return 'deal_statuses'
    }
    else if(lead_status_id == 12) {
        return 'cancel_statuses'
    }
    else {
            if(with_policy) {
                return 'policy_task_statuses'
            }
            else {
                return 'statuses'
            }
    }
}

function leadDataArray(lead) {
    return [{
        vehicle_details:`${lead.header_section}`,
        sub_title: lead.sub_title,
        lead_status: lead.lead_status_text,
        lead_status_id: lead.lead_status_id,
        task_status: lead?.misk_latest_task?.disposition_text,
        request_time_line: lead.created_at,
        due_at: lead?.misk_latest_task?.due_date,
        duplicate_source: lead.duplicate_source,
        lead_source: lead.lead_source,
        insured_for: lead.insured_for,
        bai_ref_no: lead.get_final_invoice?.quote_ref_no ?? '-',
        total_price_vat: common.formatCurrency(lead.get_final_invoice?.total_price_vat) ?? '-',
        payment_mode: lead.get_final_invoice?.payment_type_text ?? '-',
        application_status: lead.application_status,
        policy_document: lead.policy_document,
        time_lines: timelines(lead),
    }]
}

function paymentDataArray(lead) {
    return [{
        bai_ref_no: lead.get_final_invoice?.quote_ref_no ?? '-',
        approved_date: lead.get_final_invoice?.payment_date ?  common.setDate(lead.get_final_invoice?.payment_date): '-',
        payment_mode: lead.get_final_invoice?.payment_type_text ?? '-',
        total_price_vat: common.formatCurrency(lead.get_final_invoice?.total_price_vat) ?? '-',
    }]
}

function travellerDataArray(lead, j) {
    let first_name = `${lead.type_prefex ? lead.type_prefex : ''} ${lead.first_name ? lead.first_name : ''}`
    if(!lead.type_prefex && !lead.first_name) first_name = '-'
    return [{
        show: true,
        traveller_index: j,
        first_name,
        last_name: lead.last_name ? lead.last_name : '-',
        middle_name: lead.middle_name ? lead.middle_name : '-',
        lead_detail_id: lead.id,
        dob: lead.date_of_birth ? lead.date_of_birth : '-',
        gender: lead.gender ? lead.gender : '-',
        passport: lead.passport ? lead.passport : '-',
        country_of_residence: lead.country_of_residence ? lead.country_of_residence : '-',
        traveller_nationality: lead.traveller_nationality ? lead.traveller_nationality : '-',
    }]
}

function showButtons(lead) {
    const { all_lead_actions, lead_status_id, lead_driver_detail, manufacturer_id, is_end_state_lead, vehicle_type, make, model, is_show_make_invoice, latest_policy_purchase, lead_has_pending_invoice } = lead
    
    // let showNewDriver = [12, 13, 9].includes(lead_status_id)
    // let showNewCar = lead_status_id === 14
    let showNewCar = false;
    let showNewDriver = false;
    let showAddDetails = false
    const showViewTasks = true
    let showEditLeadDetails = false
    let showAddLeadDetails = false
    let showGenerateQuotePdf = false
    let showUploadDocuments = false
    let showCompareQuotes = false
    let showCompareQuotesMisk = false
    let showReviewMisk = false
    let showInitiateQuotes = false
    let showSendQuote = false
    let showMakeInvoice = false
    let showApproveInvoice = false
    let showTransferLead = false
    let showCloseLead = false
    
    if( all_lead_actions ) {
        showInitiateQuotes = (all_lead_actions.isQLShow && allowInitiateQuotes)
        showCompareQuotesMisk = (all_lead_actions.isPlansShow && allowInitiateQuotes)
        showReviewMisk = (all_lead_actions.showReviewMisk && allowInitiateQuotes)
        showApproveInvoice = all_lead_actions.isPaynowShow
        showTransferLead = all_lead_actions.is_show_transfer;
        showCloseLead = all_lead_actions.isCloseLeadShow
    }
    if( ( lead.insurance_type != 3 ) && ( lead_driver_detail && manufacturer_id != 66 && lead_driver_detail.dob !== null && lead_driver_detail.dob !== '' && vehicle_type == 1) ) {
        if(!is_end_state_lead) showNewDriver = true

        showNewCar = true
    }
    if( ( lead.insurance_type != 3 ) && ( make == 'QL' && model == 'QL' && !is_end_state_lead ) ) showAddDetails = true

    if( ( lead.insurance_type != 3 ) && ( manufacturer_id != 66 ) ) showEditLeadDetails = true
    if(lead_driver_detail && manufacturer_id != 66) {
        if(lead_driver_detail.dob !== null && lead_driver_detail.dob !== '') {
            showCompareQuotes = true

            if(lead_status_id != 4 || is_show_make_invoice) {
                showSendQuote = true
                if(![9, 10, 2].includes(lead_status_id)) showGenerateQuotePdf = true
                if(!lead_has_pending_invoice) showMakeInvoice = true
            }
        }
    }

    if( ( lead.insurance_type != 3 ) && ( 
        (is_show_make_invoice || 
            (!latest_policy_purchase || ![10,7,8,9,4,11].includes(latest_policy_purchase?.status))
        ) && ![2, 9, 10].includes(lead_status_id) ) 
    ) 
        showUploadDocuments = true

    if(( lead.insurance_type != 3 ) && ( !lead_driver_detail?.dob && manufacturer_id == 66 ) ) showAddLeadDetails = true


    return { 
        showNewDriver, 
        showNewCar, 
        showAddDetails, 
        showViewTasks, 
        showEditLeadDetails, 
        showAddLeadDetails,
        showGenerateQuotePdf,
        showUploadDocuments,
        showCompareQuotes,
        showCompareQuotesMisk,
        showReviewMisk,
        showSendQuote,
        showInitiateQuotes,
        showCloseLead,
        showMakeInvoice,
        showApproveInvoice,
        showTransferLead
     }
}

function setANT(data, amend_type) {
    return {
        amend_type,
        amount: data.amount || 0,
        car_value: data.car_value || 0,
        claimed_insurance: data.claimed_insurance || 2,
        claims: data.claims || null,
        current_policy_active: data.current_policy_active || 1,
        dob: data.dob || '',
        driver_name: data.driver_name || '',
        driving_experience: data.driving_experience || 4,
        email: data.email,
        existing_policy_expired: data.existing_policy_expired || 2,
        expiry_date_time: data.expiry_date_time || '',
        first_driving_license_country: data.first_driving_license_country || 0,
        fully_comprehensive: data.fully_comprehensive || 1,
        gender: data.gender || 0,
        insurance_payment: data.insurance_payment || 0,
        invoice_doc: data.invoice_doc || null,
        invoice_id: data.invoice_id,
        invoice_number: data.parent_merchant_reference,
        is_amend: 1,
        language: data.language || 'en',
        merchant_reference: data.merchant_reference,
        name: data.name,
        nationality: data.nationality || 0,
        new_email: data.new_email || '',
        new_name: data.new_name || '',
        new_phone_country_code: data.new_phone_country_code || '+974',
        new_phone_number: data.new_phone_number || '',
        no_claim_certificate: data.no_claim_certificate || 1,
        order_description: data.order_description || '',
        original_price: data.original_price || '',
        payment_date: data.payment_date || '',
        payment_type: data.payment_type || 0,
        personal_use: data.personal_use || 1,
        phone_number: data.phone_number,
        policy_end_date: data.policy_end_date || '',
        policy_sales_agent: data.policy_sales_agent,
        policy_sales_agent_id: data.policy_sales_agent_id,
        policy_start_date: data.policy_start_date || moment().format('YYYY-MM-DD'),
        reference_no: data.reference_no || '',
        third_party_liability: data.third_party_liability || 2,
        uae_driving_experience: data.uae_driving_experience || 4
    }
}

function setNotNT(data, amend_type) {
    return {
        amend_type,
        amount: data.amount || 0,
        email: data.email,
        expiry_date_time: data.expiry_date_time || '',
        insurance_payment: data.insurance_payment || 0,
        invoice_doc: data.invoice_doc || null,
        invoice_id: data.invoice_id,
        invoice_number: data.parent_merchant_reference,
        is_amend: 1,
        language: data.language || 'en',
        merchant_reference: data.merchant_reference,
        name: data.name,
        order_description: data.order_description || '',
        original_price: data.original_price || '',
        payment_date: data.payment_date || '',
        payment_type: data.payment_type || 0,
        phone_number: data.phone_number,
        existing_policy_end_date: data.policy_end_date || '',
        policy_end_date: '',
        policy_sales_agent: data.policy_sales_agent,
        policy_sales_agent_id: data.policy_sales_agent_id,
        reference_no: data.reference_no || ''
    }
}
